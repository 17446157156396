*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body{
    background: var(--color-bg);
    overflow-x: hidden;
}

a{
    color: unset;
    text-decoration: none;
}

.gradient_bg{
    background:-moz-radial-gradient(circle at 50% 50%, rgba(74, 137, 187, 0.8) 2%, rgba(26, 118, 190, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 50% 50%, rgba(74, 137, 187, 0.8) 2%, rgba(26, 118, 190, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 50% 50%, rgba(74, 137, 187, 0.8) 2%, rgba(26, 118, 190, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 50% 50%, rgba(74, 137, 187, 0.8) 2%, rgba(26, 118, 190, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 50% 50%, rgba(74, 137, 187, 0.8) 2%, rgba(26, 118, 190, 1) 100%);

}

.gradient_text{
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient_text_2{
    background: var(--gradient-text-2);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.blue{
    background-color: rgb(48, 119, 167);
}

.section_padding{
    padding: 4rem 6rem;
}

.section_margin{
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}
@keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}

@media screen and (max-width: 700px) {
    .section_padding{
        padding: 4rem;
    }
    .section_margin{
        margin: 4rem;
    }
}

@media screen and (max-width: 550px) {
    .section_padding{
        padding: 4rem 2rem;
    }
    .section_margin{
        margin: 4rem 2rem;
    }
}
  
.gpt3_footer-copyright{
    padding: 1rem 0;
    background: var(--color-footer);
    margin: 1rem 0 ;
    text-align: center;
    width: 100%;
}

.gpt3_footer-copyright p{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: var(--color-text);
}