@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #ceb48dda 1.84%, #fcfbfa 102.67%);
  --gradient-text-2: linear-gradient(89.97deg,#a17143 1.84%, #772d1b 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #ebc4af 99.55%);
  
  --color-bg: #ffffff;
  --color-footer : #053e77;
  --color-blog: #042c54;
  --color-text: #fff;
  --color-text2: rgb(255, 255, 255);
  --color-subtext: #FF8A71;
  --color-text3: ;

}