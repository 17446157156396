.gpt3_footer{
    border: 1px solid whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
}

.gpt3_footer-heading{
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}

.gpt3_footer-heading h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 52px;
    line-height: 65px;
    text-align: center;
    letter-spacing: -0.04em;
}

.gpt3_footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    text-align: left;
}

.gpt3_footer-links_logo{
    display:flex;
    flex-direction: column;
}

.gpt3_footer-links_logo-image{
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
}

.gpt3_footer-links_logo p{
    color: #fff;
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 2rem;
}

.gpt3_footer-links_logo-image h2{
    margin-left: 0.5rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    cursor: pointer;
}


.gpt3_footer-links_logo span{
    color: #000;
}

.gpt3_footer-links_logo-image img{
    width: 80px;
    height: 60px;
    border-radius: 50px;
}

.gpt3_footer-links div{
    width: 250px;
    margin-top: 0.2rem;
}

.gpt3_footer-links_div{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom:2rem ;
}

.gpt3_footer-links_div h4{
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 16px;
    color: var(--color-text2);
    margin-bottom: 1rem;
}

.gpt3_footer-links_div p{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: var(--color-text);
    margin: 0.5rem 0;
    cursor: pointer;
}

@media screen and (max-width: 850px) {
    .gpt3_footer-heading h1{
        font-size: 44px;
        line-height: 50px;
    }

}

@media screen and (max-width: 550px) {
    .gpt3_footer-heading h1{
        font-size: 34px;
        line-height: 40px;
    }
}

@media screen and (max-width: 380px) {
    .gpt3_footer-heading h1{
        font-size: 27px;
        line-height: 37px;
    }
}