.gpt3_blog-container_article{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.gpt3_blog-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-footer);
}

.gpt3_blog-container_article-image img{
    width: 100%;
    height: 100%;
}

.gpt3_blog-container_article-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.gpt3_blog-container_article-content h3{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20.11px;
    line-height: 30px;
    color: var(--color-text2);
    margin-bottom: 1rem;
}

.gpt3_blog-container_article-content p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: var(--color-text);
}

@media screen and (max-width: 550px){
    .gpt3_blog-container_article-content h3{
        font-size: 16px;
        line-height: 23px;
    }
}
