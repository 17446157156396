.gpt3_cta{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #2465a7 25%, rgb(97, 132, 167) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #5896d3 25%, rgb(45, 114, 182) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #1468bd 25%, rgb(28, 131, 235) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #3578bb 25%, rgb(133, 182, 231) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #3276bb 25%, rgb(28, 142, 255) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gpt3_cta-content p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: var(--color-text);
}

.gpt3_cta-content h3{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 40px;
    line-height: 45px;
    color: var(--color-text2);
}

.gpt3_cta-btn{
    justify-content: center;
    align-items: center;
    margin: 2rem 0 1rem;
}

.gpt3_cta-btn button{
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    background: var(--color-footer);
    border-radius: 15px;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    cursor: pointer;
    color: var(--color-text);
    outline: none;
    padding: 0 5rem;
    border: none;
}

@media screen and (max-width: 880px) {
    .gpt3_cta{
        flex-direction: column;
    }

    .gpt3_cta-btn{
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .gpt3_cta{
        margin: 4rem 2rem;
    }

    .gpt3_cta-content h3{
        font-size: 25px;
        line-height: 35px;
    }

    .gpt3_cta-btn button{
        font-size: 15px;
        line-height: 28px;
    }
}